body {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2em;
    line-height: 1.2em;
    color: #717175;
}
h2 {
    color: #B31E39;
    font-size: 1.6em;

}
.header {
    background: rgba(113, 113, 117, .1);
    text-align: center;

}
.mini {
    font-size: 50%;
}
.logo__header {
    width: 100%;
    max-width: 400px;
}
.master {
    padding: 10px 15px;
}
.intro {
    font-weight: 500;
    margin-bottom: 40px;
}

.btn-main {
    display: inline-block;
    border: 0;
    background: #2E468A;
    color: #FFF;
    font-size: 1.1em;
    padding: 8px 40px;
}
.btn-main:hover {
    background: rgba(46, 70, 138, 0.877);
}
.btn-back {
    display: inline-block;
    border: 0;
    background: #717175;
    color: #FFF;
    font-size: 1.1em;
    padding: 8px 20px;
}
.btn-back:hover {
    background: #525255;
}
.btn__ficha {
    display: inline-block;
    border: 0;
    background: #B31E39;
    color: #FFF;
    font-size: 1.1em;
    padding: 8px 10px;
    margin-right: 3px;
}
.btn-ficha:hover {
    background: rgba(179, 30, 57, 0.781);
}
.btn__ficha img {
    width: 29px;
    vertical-align: top;
}
.input-quelante {
    max-width: 100px;
    margin: 0 auto;
}
.siono {
    display: none;
}
.siono + label {
    cursor: pointer;
    display: block;
    padding: 10px 30px;
    border: 1px solid #999;
    border-radius: 10px;
    color: #B31E39;
    box-shadow: 1px 6px 9px 0px #BBB;
    transition: 0.5s;
}
.siono + label:hover {
    background: #EEE;
}
.siono:checked + label {
    background: #2E468A;
    border: 1px solid #2E468A;
    color: #FFF;
    box-shadow: none;
}
.tratam + label{
    font-size: 0.4em;
    padding: 1px 5px;
}
.titulos .col {
    font-size: 0.7em;
    background: #2E468A;
    color: #FFF;
    border: 1px solid #FFF;
}
.resultados {
    font-size: 0.6em;
    color: #FFF;
    border-radius: 20px;
    line-height: 1.3em;
    display: flex;
}
.resultados .col, .resultados .col-12 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    word-break: break-word;
    line-height: 1em;
}
.peque {
    font-size: .42em;
}
.resultados .titulo {
    color: #FFF;
    border: 2px solid #FFF;
    background: #2E468A;

}
.result__image {
    vertical-align: bottom;
}
.result__type {
    background: rgba(27, 25, 26, 0.5);
    color: #FFF;
    font-size: 0.7em;
    border: 2px solid #FFF;
}
.yellow {
    background: #FAAF06;
    min-height: 20px;
    color: #2E468A;
    border: 2px solid #FFF;
    padding: 0;
}
.red {
    background: #B31E39;
    min-height: 20px;
    border: 2px solid #FFF;
    padding: 0;
}
.green {
    background: #04C931;
    min-height: 20px;
    border: 2px solid #FFF;
    padding: 0;
}
.white {
    background: #FFF;
    min-height: 20px;
    color: #2E468A;
    border: 2px solid #EEE;
    padding: 0;
}
.text-blue {
    color: #2E468A;
    font-weight: bold;
}
.leyenda {
    font-size: 0.6em;
    max-width: 400px;
    margin: 0 auto;
    padding-bottom: 10px;
    
}
.spot {
    margin-bottom: 5px;
}
.spot span {
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 50%;
    background: grey;
    margin: 0 auto;
}
.spot.rojo span {
    background: #B31E39;
}
.spot.amarillo span {
    background: #FAAF06;
}
.spot.verde span {
    background: #04C931;
}
.leyenda p {
    line-height: 1.3em;
}
p.advisory {
    font-size: 0.5em;
}
p.reference {
    font-size: 0.5em;
    line-height: 1em;
}
.btn__container{
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 10px 0;
    box-shadow: -3px 1px 3px 0px #8a8a8a;
    background: #FFF;
}
.padded {
    padding-top: 3%;
    padding-bottom: 10%;
}
.intro p {
    font-size: .7em;
    line-height: 1.4em;
}
.largepad {
    padding-top: 20px;
}
.logocontainer {
    max-width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    body {
        font-size: 1.5em;
    }
    .logo__header {
        width: 50%;
    }
    .master {
        padding: 10px 15px;
    }
    h2 {
        font-size: 1.1em;
    }
    h2 img {
        width: 25px;
        vertical-align: bottom;
    }
    .result__image {
        vertical-align: baseline;
    }
    .leyenda {
        font-size: 0.5em;
    }
    .padded {
        padding-top: 6%;
        padding-bottom: 10%;
    }
    .largepad {
        padding-top: 10px;
    }
}